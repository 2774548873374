const getEnv = 'PRODUCTION';
// const getEnv = 'QA';
// const getEnv = 'PRODUCTION';

const store = 'bitsy';
// const store = 'bitsy';
// const store = 'wtebump';
// const store = 'pnmbump';

const getGoogleOptimizeId = {
  wtebump: 'GTM-PZPMRDB',
  pnmbump: null,
  bitsy: 'OPT-P7H9DQB',
  bump: 'GTM-W7BNX5Q',
};

const getGoogleAdWordsId = {
  wtebump: 'AW-969327984',
  pnmbump: 'AW-969327984',
  bitsy: 'AW-803829201',
  bump: 'AW-969327984',
};

let getUATrackingId = {
  wtebump: 'UA-134509754-1',
  pnmbump: 'UA-136887062-1',
  bitsy: 'UA-118644809-1',
  bump: 'UA-49488496-1',
};
if (getEnv !== 'PRODUCTION') {
  getUATrackingId = {
    wtebump: 'UA-49488496-3',
    pnmbump: 'UA-49488496-3',
    bitsy: 'UA-49488496-3',
    bump: 'UA-49488496-3',
  };
}

const getGoogleAnalyticsTrackingId = {
  wtebump: 'G-W1NJZJ51Q7',
  pnmbump: 'G-W1NJZJ51Q7',
  bitsy: 'G-19PQFP3BC6',
  bump: 'G-W1NJZJ51Q7',
};

const getGTMId = {
  wtebump: 'GTM-PSLL274',
  pnmbump: 'GTM-PKF5FQ',
  bitsy: 'GTM-5K3BKKW',
  bump: 'GTM-PKF5FQ',
};

const getFacebookPixelId = {
  wtebump: '1506270289506684',
  pnmbump: '1061159043910150',
  bitsy: '181961985795589',
  bump: '1061159043910150',
};

const getStripePublicAPIKey = {
  bump: {
    DEVELOPMENT: 'pk_test_ECwJYpjwrPGLxb0hlooiZebE00x4wE2loE',
    QA: 'pk_test_ECwJYpjwrPGLxb0hlooiZebE00x4wE2loE',
    PRODUCTION: 'pk_live_mq1fdZqt6v91Nmlyup6CTdXP',
  },
  bitsy: {
    DEVELOPMENT: 'pk_test_ECwJYpjwrPGLxb0hlooiZebE00x4wE2loE',
    QA: 'pk_test_ECwJYpjwrPGLxb0hlooiZebE00x4wE2loE',
    PRODUCTION: 'pk_live_mq1fdZqt6v91Nmlyup6CTdXP',
  },
  wtebump: {
    DEVELOPMENT: 'pk_test_fY4zE2SqrYJKTNnhv2FwF8w8',
    QA: 'pk_test_fY4zE2SqrYJKTNnhv2FwF8w8',
    PRODUCTION: 'pk_live_J1OYKxMcsBrtnAQRDeEoztup',
  },
  pnmbump: {
    DEVELOPMENT: 'pk_test_v7CyNHC2FWl0u2eiDHlTy85W00WqY1u8RV',
    QA: 'pk_test_v7CyNHC2FWl0u2eiDHlTy85W00WqY1u8RV',
    PRODUCTION: 'pk_live_GguPOq14MSKef00NF47H6oTW00G5h1DDUD',
  },
};

const getStripeApiVersion = {
  DEVELOPMENT: '2020-08-27',
  QA: '2018-02-28',
  PRODUCTION: '2018-02-28',
};

const selfDomainMap = {
  bitsy: {
    DEVELOPMENT: 'http://localhost:3000',
    QA: 'https://bitsy.bumptesting.com',
    PRODUCTION: 'https://bitsyboxes.com',
  },
  bump: {
    DEVELOPMENT: 'http://localhost:3000',
    QA: 'https://bump.bumptesting.com',
    PRODUCTION: 'https://bumpboxes.com',
  },
  wtebump: {
    DEVELOPMENT: 'http://localhost:3000',
    QA: 'https://wte.bumptesting.com',
    PRODUCTION: 'https://whattoexpect.bumpboxes.com',
  },
  pnmbump: {
    DEVELOPMENT: 'http://localhost:3000',
    QA: 'https://pnm.bumptesting.com',
    PRODUCTION: 'https://pnmag.bumpboxes.com',
  },
};

const adminDomain = {
  DEVELOPMENT: 'http://localhost:3001',
  QA: 'https://admin.bumptesting.com',
  PRODUCTION: 'https://administrator.bitsyboxes.com',
};

const useDynamicFaqDme = {
  DEVELOPMENT: true,
  QA: true,
  PRODUCTION: true,
};

const blogApiDomainMap = {
  bitsy: {
    DEVELOPMENT: 'http://localhost:3001',
    QA: 'https://blog-cdn.bitsyboxes.com',
    PRODUCTION: 'https://blog-cdn.bitsyboxes.com',
  },
  bump: {
    DEVELOPMENT: 'https://blog.bumpboxes.com',
    QA: 'https://blog-cdn.bumpboxes.com',
    PRODUCTION: 'https://blog-cdn.bumpboxes.com',
  },
  wtebump: {
    DEVELOPMENT: 'https://blog.bumpboxes.com',
    QA: 'https://blog-cdn.bumpboxes.com',
    PRODUCTION: 'https://blog-cdn.bumpboxes.com',
  },
  pnmbump: {
    DEVELOPMENT: 'https://blog.bumpboxes.com',
    QA: 'https://blog-cdn.bumpboxes.com',
    PRODUCTION: 'https://blog-cdn.bumpboxes.com',
  },
};

const blogCanonicalUrlMap = {
  bitsy: {
    DEVELOPMENT: 'http://localhost:3000',
    QA: 'https://bitsy.bumptesting.com',
    PRODUCTION: 'https://bitsyboxes.com',
  },
  bump: {
    DEVELOPMENT: 'http://localhost:3000',
    QA: 'https://bump.bumptesting.com',
    PRODUCTION: 'https://bumpboxes.com',
  },
};

const devApiDomainWithoutGraphQl = 'http://localhost:4000';
const QaApiDomainWithoutGraphQl = 'https://api.bumptesting.com';
const domainWithoutGraphQLMap = {
  bump: {
    DEVELOPMENT: devApiDomainWithoutGraphQl,
    QA: QaApiDomainWithoutGraphQl,
    PRODUCTION: 'https://api.bumpboxes.com',
  },
  bitsy: {
    DEVELOPMENT: devApiDomainWithoutGraphQl,
    QA: QaApiDomainWithoutGraphQl,
    PRODUCTION: 'https://api.bitsyboxes.com',
  },
  wtebump: {
    DEVELOPMENT: devApiDomainWithoutGraphQl,
    QA: QaApiDomainWithoutGraphQl,
    PRODUCTION: 'https://api.bumpboxes.com',
  },
  pnmbump: {
    DEVELOPMENT: devApiDomainWithoutGraphQl,
    QA: QaApiDomainWithoutGraphQl,
    PRODUCTION: 'https://api.bumpboxes.com',
  },
};

const devApiDomain = 'http://localhost:4000/graphql';
const QaApiDomain = 'https://api.bumptesting.com/graphql';
const domainMap = {
  bump: {
    DEVELOPMENT: devApiDomain,
    QA: QaApiDomain,
    PRODUCTION: 'https://api.bumpboxes.com/graphql',
  },
  bitsy: {
    DEVELOPMENT: devApiDomain,
    QA: QaApiDomain,
    PRODUCTION: 'https://api.bitsyboxes.com/graphql',
  },
  wtebump: {
    DEVELOPMENT: devApiDomain,
    QA: QaApiDomain,
    PRODUCTION: 'https://api.bumpboxes.com/graphql',
  },
  pnmbump: {
    DEVELOPMENT: devApiDomain,
    QA: QaApiDomain,
    PRODUCTION: 'https://api.bumpboxes.com/graphql',
  },
};

const getStatsigKey = {
  DEVELOPMENT: 'client-yiMznCAahc2VE46aTDuA7EJJ04FQLLfEb8Mwhzam95e',
  QA: 'client-lRjGCc3oPIsTkJOj7RaggGzTaPRfHjRvsBvPUWb9JYB',
  PRODUCTION: 'client-hkxXPjkRZTX5kiIjLJIy8mNCnvVGbquUohQ6H7w3Rfy',
};

const getStatsigEnvironment = {
  DEVELOPMENT: 'development',
  QA: 'staging',
  PRODUCTION: 'production',
};

module.exports = {
  getEnv,
  store,
  getGoogleAnalyticsTrackingId: getGoogleAnalyticsTrackingId[store],
  getUATrackingId: getUATrackingId[store],
  getStripePublicAPIKey: getStripePublicAPIKey[store][getEnv],
  getStripeApiVersion: getStripeApiVersion[getEnv],
  getSelfDomain: selfDomainMap[store][getEnv],
  getDomain: domainMap[store][getEnv],
  getAdminDomain: adminDomain[getEnv],
  domainWithoutGraphQL: domainWithoutGraphQLMap[store][getEnv],
  getGTMId: getGTMId[store],
  getFacebookPixelId: getFacebookPixelId[store],
  getGoogleAdWordsId: getGoogleAdWordsId[store],
  getGoogleOptimizeId: getGoogleOptimizeId[store],
  getBlogApiDomain: blogApiDomainMap[store][getEnv],
  getUseDynamicFaqDme: useDynamicFaqDme[getEnv],
  getStatsigKey: getStatsigKey[getEnv],
  getStatsigEnvironment: getStatsigEnvironment[getEnv],
  getBlogCanonicalUrl: blogCanonicalUrlMap[store][getEnv],
};
